























import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import UnitInformation from '@/components/organisms/v3/UnitInformation.vue'
import StudentBottomPanel from '@/components/organisms/v3/StudentBottomPanel.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'

export type Unit = {
  curriculumSUnitId: number
  subjectCode: string
  name: string
}

@Component({
  components: {
    SidebarSwitcher,
    ButtonBase,
    SelectBase,
    UnitInformation,
    StudentBottomPanel,
  },
})
export default class CurriculumSUnits extends Mixins(ClassModeMethods) {
  private gradeOptionDatas = [
    { text: '小１', value: 's1' },
    { text: '小２', value: 's2' },
    { text: '小３', value: 's3' },
    { text: '小４', value: 's4' },
    { text: '小５', value: 's5' },
    { text: '小６', value: 's6' },
    { text: '中１', value: 't1' },
    { text: '中２', value: 't2' },
    { text: '中３', value: 't3' },
  ]

  private subjectNames = {
    su: '算数/数学',
    ei: '英語',
    ko: '国語',
    sh: '社会',
    ri: '理科',
  }

  private unitDatas: Unit[] = []

  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId

  private classMode = this.$route.params.classMode
  private subjectCode = this.$route.params.subjectCode

  private async mounted(): Promise<void> {
    window.scrollTo(0, 0)
    Vue.prototype.$loading.start()
    await this.loadDatas()
    Vue.prototype.$loading.complete()
  }

  private async loadDatas(): Promise<void> {
    const listFetch = this.gradeOptionDatas.map((grade) =>
      Vue.prototype.$http.httpWithToken.get('/v3/curriculumSUnits', {
        params: {
          studyLogicType: 'AI',
          userId: this.userId,
          subjectCode: this.subjectCode,
          gradeCode: grade.value,
          classModeCode: this.classMode,
        },
      })
    )
    try {
      const results = await Promise.all(listFetch)
      results.forEach((result: any) => {
        const dataUnits = result.data.map((item: any) => {
          const gradeOption = this.gradeOptionDatas.find((grade) => grade.value === result.config.params.gradeCode)
          if (gradeOption) {
            if (
              item.name.trim().endsWith(`（${gradeOption.text}）`) ||
              item.name.trim().endsWith(`〈${gradeOption.text}〉`)
            ) {
              return item
            }
            return { ...item, name: item.name + ` （${gradeOption.text}）` }
          }
        })
        this.unitDatas = [...this.unitDatas, ...dataUnits]
      })
    } catch (error) {
      this.$router.push('/error/error400')
    }
  }

  /**
   * クレジットリンク表示ステータス
   */
  private get isShowCreditLink() {
    return this.subjectCode == 'sh'
  }

  private get selectSubjectUrl() {
    return `/student/v3/subjects/${this.classMode}`
  }

  private get classModeName() {
    let modeName = ''
    if (this.$store.getters['drillsV3/modeActive']) {
      modeName = this.$store.getters['drillsV3/modeActive'].name
    }
    return modeName
  }

  private get isAiMode(): boolean {
    return this.classMode == this.CLASS_MODE.AI
  }
}
